import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik"
import React, { useEffect, useState } from "react"

import {
  formField,
  formFieldError,
  formSubmit,
  formRow,
  formGroup,
  thankYouMessage,
  modalFooter,
  modalAccept,
  modalCancel,
  modalCheckout,
  modalCheckoutItem,
  modalCheckoutPrice,
  subtitle,
  candidateList,
  candidateInput,
  divider,
  dividerWrapper,
  dividerButton,
  dividerButtonWrapper,
  buttonWithDividerContainer,
  dividerButtonIcon,
} from "./dynamic-form-builder.module.css"

import { onFormBuilderSubmit } from "../../services/form-builder"

import { trackEvent as analyticsTrackEvent } from "../../modules/analytics"
import firebase from "gatsby-plugin-firebase"
import { navigate } from "gatsby"
import ModalWrapper from "../modal-wrapper/modal-wrapper"
import { CountryDropdownField } from "../country-select/country-select"
import CandidateAccordionItem from "../candidate-accordion/candidate-accordion-item"
import { object } from "prop-types"
import { fireVWOEvent } from "../../services/vwo-handler"

const DynamicFormBuilder = ({
  formData,
  productId,
  productName,
  productSubtitle,
  formType,
  formIndex,
  asset,
  productPrice,
}) => {
  const [submitted, setSubmitted] = useState(false)
  const [user, setUser] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [isTouched, setIsTouched] = useState(false)
  const [dirtyFields, setDirtyFields] = useState([])

  const mapFieldTypeToInputType = (fieldType) => {
    switch (fieldType.toLowerCase()) {
      case "tekst":
        return "text"
      case "email":
        return "email"
      case "telefoonnummer":
        return "tel"
      case "datum":
        return "date"
      case "textarea":
        return "textarea"
      case "checkbox":
        return "checkbox"
      case "radio":
        return "radio"
      case "land select":
        return "countrypicker"
      case "kandidaat toevoegen":
        return "customtsl" // Custom The Selection Lab candidate select component
      default:
        return "text" // Default to 'text' if the type is not recognized
    }
  }

  const getInitialValues = () => {
    let initialValues = {}

    formData.rows.forEach((section) => {
      section.inputFields.forEach((field) => {
        // Set initial value based on field type
        if (field.type == "Land select") {
          initialValues[field.label.toLowerCase().replaceAll(".", "")] = "NL"
        } else if (field.type === "Checkbox") {
          initialValues[field.label.toLowerCase().replaceAll(".", "")] = false
        } else if (field.type === "Kandidaat toevoegen") {
          initialValues.users = [
            {
              firstName: "",
              lastName: "",
              email: "",
              language: "",
              iq_test: false,
            },
          ]
        } else {
          initialValues[field.label.toLowerCase().replaceAll(".", "")] = ""
        }
      })
    })
    return initialValues
  }

  const renderMarkdownLink = (textWithLink) => {
    const regex = /\[([^\]]+)\]\(([^")]+)(?:\s"([^"]+)")?\)/
    const match = textWithLink.match(regex)

    if (match) {
      const fullMatch = match[0]
      const text = match[1]
      const url = match[2]
      const title = match[3] ? match[3] : ""

      return (
        <>
          {textWithLink.split(fullMatch)[0]}
          <a href={url} target="_blank" rel="noopener noreferrer" title={title}>
            {text}
          </a>
          {textWithLink.split(fullMatch)[1]}
        </>
      )
    } else {
      return textWithLink
    }
  }

  useEffect(() => {
    if (!firebase) return
    firebase.auth().onAuthStateChanged(async (authUser) => {
      if (!authUser) return await firebase.auth().signInAnonymously()
      setUser(authUser)
    })
  }, [user])

  const validateForm = (values) => {
    const errors = {}
    const userErrors = [] // Initialize userErrors here

    // Validate the regular form fields (outside of the FieldArray)
    formData.rows.forEach((section) => {
      section.inputFields.forEach((field) => {
        if (field.type === "Kandidaat toevoegen") {
          if (values.users.length === 0) {
            errors.usersCount = "Voeg een kandidaat toe"
          }
        } else if (
          field.isRequired &&
          !values[field.label.toLowerCase().replaceAll(".", "")]
        ) {
          errors[
            field.label.toLowerCase().replaceAll(".", "")
          ] = `${field.label} is verplicht`
        }
      })
    })

    // Validate the users FieldArray
    if (values.users) {
      values.users.forEach((user, index) => {
        const userError = {}

        // Validate each user field
        if (!user.firstName) userError.firstName = "Voornaam is verplicht"
        if (!user.lastName) userError.lastName = "Achternaam is verplicht"
        if (!user.email) userError.email = "E-mailadres is verplicht"
        if (!user.language) userError.language = "Taal is verplicht"
        if (user.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
          userError.email = "Ongeldig e-mailadres"
        }

        // Push the userError object into the userErrors array regardless of whether there are errors or not.
        userErrors.push(userError)
      })

      // Add userErrors to errors only if it contains errors for at least one user
      if (userErrors.some((err) => Object.keys(err).length > 0)) {
        errors.users = userErrors
      }
    }

    console.log(`🤕 Errors: ${JSON.stringify(errors)}`)
    return errors
  }

  const createBlobAndDownload = async (url) => {
    // Fetch file data using fetch or useAxios
    const response = await fetch(url)

    // Check for successful response
    if (!response.ok) {
      throw new Error("Failed to download file")
    }

    // Create a blob and download it
    const blob = await response.blob()
    const downloadLink = document.createElement("a")
    downloadLink.href = window.URL.createObjectURL(blob)
    downloadLink.download = url.split("/").pop() // Extract filename
    downloadLink.click()
    window.URL.revokeObjectURL(downloadLink.href)
  }

  const initialValues = getInitialValues()

  useEffect(() => {
    if (isTouched) {
      analyticsTrackEvent(`form${formIndex}_start`)
      fireVWOEvent("formStart", { formStart: true })
    }
  }, [isTouched])

  const handleFieldChange = (fieldName, value) => {
    if (dirtyFields.includes(fieldName)) return

    // Check if field is dirty
    if (initialValues[fieldName] !== value) {
      analyticsTrackEvent(
        `form${formIndex}_field_${fieldName.substring(0, 22)}`
      )
      setDirtyFields((prevState) => [...prevState, fieldName])
    }
  }

  const handleCandidateFieldChange = (candidateIndex, fieldName, value) => {
    const candidateFieldName = `users.${candidateIndex}.${fieldName}`
    if (dirtyFields.includes(candidateFieldName)) return

    // Check if field is dirty
    if (initialValues[candidateFieldName] !== value) {
      analyticsTrackEvent(
        `form${formIndex}_field_cand${candidateIndex}_${fieldName.substring(
          0,
          15
        )}`
      )
      setDirtyFields((prevState) => [...prevState, candidateFieldName])
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={validateForm}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (formData?.submitEvent === "download" && asset?.file?.url) {
              createBlobAndDownload(asset.file.url)
            }

            analyticsTrackEvent(`form${formIndex}_submit`)
            fireVWOEvent("formSubmit", { clickOnButton: true })
            if (formData.useMollie) {
              const { checkoutUrl } = await onFormBuilderSubmit(
                productId,
                values,
                formType
              )
              navigate(checkoutUrl)
            } else {
              onFormBuilderSubmit(productId, values, formType)
            }

            setSubmitted(true)
          } catch (err) {
            setSubmitting(false)
          }
        }}
      >
        {({
          handleChange,
          touched,
          values,
          isSubmitting,
          submitForm,
          errors,
        }) => {
          if (Object.values(touched).some((val) => val === true))
            setIsTouched(true)
          return (
            <Form>
              {formData.useMollie && (
                <ModalWrapper
                  showModal={showModal}
                  setShowModal={setShowModal}
                  title="Besteloverzicht"
                >
                  <div className={modalCheckout}>
                    <div>
                      <div className={modalCheckoutItem}>
                        <span>{productName}</span>
                        {productSubtitle && (
                          <span className={subtitle}>{productSubtitle}</span>
                        )}
                      </div>
                      <span className={modalCheckoutPrice}>
                        €
                        {Number(productPrice * (values.users?.length || 1))
                          .toFixed(2)
                          .replaceAll(".", ",")}
                      </span>
                    </div>
                    <hr />
                    <div>
                      <span>Subtotaal</span>
                      <span className={modalCheckoutPrice}>
                        €
                        {Number(productPrice * (values.users?.length || 1))
                          .toFixed(2)
                          .replaceAll(".", ",")}
                      </span>
                    </div>
                    <div>
                      <span>Btw (21%)</span>
                      <span className={modalCheckoutPrice}>
                        €
                        {(
                          Number(productPrice * (values.users?.length || 1)) *
                          0.21
                        )
                          .toFixed(2)
                          .replaceAll(".", ",")}
                      </span>
                    </div>
                    <hr />
                    <div>
                      <span>Totaal</span>
                      <span className={modalCheckoutPrice}>
                        €
                        {(
                          Number(productPrice * (values.users?.length || 1)) *
                          1.21
                        )
                          .toFixed(2)
                          .replaceAll(".", ",")}
                      </span>
                    </div>
                  </div>

                  <div className={modalFooter}>
                    <button
                      className={modalAccept}
                      style={{ margin: "20px 0px" }}
                      type="submit"
                      onClick={() => {
                        analyticsTrackEvent(`form${formIndex}_order_confirm`)
                        submitForm()
                        setShowModal(false)
                      }}
                    >
                      Betalen
                    </button>
                  </div>
                </ModalWrapper>
              )}
              {formData.rows.map((row) => (
                <div className={formRow} key={row.id}>
                  {row.inputFields.map((field) => {
                    const fieldType = mapFieldTypeToInputType(field.type)
                    return (
                      <div className={formGroup} key={field.id}>
                        {fieldType === "checkbox" && (
                          <label
                            htmlFor={field.label}
                            style={{ marginTop: "15px" }}
                          >
                            <Field
                              validate={(value) =>
                                field.isRequired && !value
                                  ? `Dit veld is verplicht`
                                  : undefined
                              }
                              onChange={(e) => {
                                handleChange(e)
                                handleFieldChange(
                                  field.label.toLowerCase().replaceAll(".", ""),
                                  e.target.value
                                )
                              }}
                              style={{ height: "unset" }}
                              type={fieldType}
                              name={field.label
                                .toLowerCase()
                                .replaceAll(".", "")}
                              disabled={submitted}
                            />
                            <span>{renderMarkdownLink(field.label)}</span>
                          </label>
                        )}

                        {fieldType === "radio" && (
                          <label
                            htmlFor={field.label}
                            style={{ marginTop: "15px" }}
                          >
                            {field.label}
                            {field.isRequired && (
                              <span style={{ color: "gray" }}>*</span>
                            )}
                            <div role="group">
                              {field.items.map((radioItem) => {
                                return (
                                  <label key={radioItem}>
                                    <Field
                                      type="radio"
                                      onChange={(e) => {
                                        handleChange(e)
                                        handleFieldChange(
                                          field.label
                                            .toLowerCase()
                                            .replaceAll(".", ""),
                                          e.target.value
                                        )
                                      }}
                                      name={field.label
                                        .toLowerCase()
                                        .replaceAll(".", "")}
                                      value={radioItem}
                                    />
                                    {radioItem}
                                  </label>
                                )
                              })}
                            </div>
                          </label>
                        )}

                        {fieldType === "customtsl" && (
                          <label
                            htmlFor="users"
                            style={{ marginTop: "15px", width: "100%" }}
                          >
                            {field.type}
                            {field.isRequired && (
                              <span style={{ color: "gray" }}>*</span>
                            )}
                            <div
                              style={{
                                width: "100%",
                                margin: "0 auto",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0 auto",
                                }}
                              >
                                <dl
                                  style={{
                                    marginTop: "24px",
                                    display: "grid",
                                    gap: "24px",
                                  }}
                                >
                                  <FieldArray name="users">
                                    {({ insert, remove, push }) => (
                                      <>
                                        {values?.users?.length > 0 &&
                                          values.users.map((user, index) => (
                                            <CandidateAccordionItem
                                              key={index}
                                              title={
                                                user.firstName
                                                  ? `${user.firstName} ${user.lastName}`
                                                  : `Kandidaat ${index + 1}`
                                              }
                                            >
                                              {/* Kandidaat item */}
                                              <div className={candidateList}>
                                                {/* VOORNAAM */}
                                                <div className={candidateInput}>
                                                  <label htmlFor={fieldType}>
                                                    <span>
                                                      Voornaam kandidaat
                                                    </span>
                                                    {field.isRequired && (
                                                      <span
                                                        style={{
                                                          color: "gray",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                  <Field
                                                    validate={(value) =>
                                                      field.isRequired && !value
                                                        ? `Dit veld is verplicht`
                                                        : undefined
                                                    }
                                                    className={formField}
                                                    placeholder={
                                                      field.placeholder
                                                    }
                                                    rows={6}
                                                    style={{
                                                      width: "100%",
                                                      resize: "none",
                                                    }}
                                                    as="input"
                                                    type="text"
                                                    name={`users.${index}.firstName`}
                                                    disabled={submitted}
                                                    onChange={(e) => {
                                                      handleChange(e)
                                                      handleCandidateFieldChange(
                                                        index,
                                                        "firstName",
                                                        e.target.value
                                                      )
                                                    }}
                                                  />
                                                  <ErrorMessage
                                                    name={`users.${index}.firstName`}
                                                    component="div"
                                                    className={formFieldError}
                                                  />
                                                </div>
                                                {/* ACHTERNAAM */}
                                                <div className={candidateInput}>
                                                  <label htmlFor={field.label}>
                                                    <span>
                                                      Achternaam kandidaat
                                                    </span>
                                                    {field.isRequired && (
                                                      <span
                                                        style={{
                                                          color: "gray",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                  <Field
                                                    validate={(value) =>
                                                      field.isRequired && !value
                                                        ? `Dit veld is verplicht`
                                                        : undefined
                                                    }
                                                    className={formField}
                                                    placeholder={
                                                      field.placeholder
                                                    }
                                                    rows={6}
                                                    style={{
                                                      width: "100%",
                                                      resize: "none",
                                                    }}
                                                    as="input"
                                                    type="text"
                                                    name={`users.${index}.lastName`}
                                                    disabled={submitted}
                                                    onChange={(e) => {
                                                      handleChange(e)
                                                      handleCandidateFieldChange(
                                                        index,
                                                        "lastName",
                                                        e.target.value
                                                      )
                                                    }}
                                                  />
                                                  <ErrorMessage
                                                    name={`users.${index}.lastName`}
                                                    component="div"
                                                    className={formFieldError}
                                                  />
                                                </div>
                                                {/* EMAIL */}
                                                <div className={candidateInput}>
                                                  <label htmlFor={field.label}>
                                                    <span>
                                                      E-mailadres kandidaat
                                                    </span>
                                                    {field.isRequired && (
                                                      <span
                                                        style={{
                                                          color: "gray",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                  <Field
                                                    validate={(value) =>
                                                      field.isRequired && !value
                                                        ? `Dit veld is verplicht`
                                                        : undefined
                                                    }
                                                    className={formField}
                                                    placeholder={
                                                      field.placeholder
                                                    }
                                                    rows={6}
                                                    style={{
                                                      width: "100%",
                                                      resize: "none",
                                                    }}
                                                    as="input"
                                                    type="email"
                                                    name={`users.${index}.email`}
                                                    disabled={submitted}
                                                    onChange={(e) => {
                                                      handleChange(e)
                                                      handleCandidateFieldChange(
                                                        index,
                                                        "email",
                                                        e.target.value
                                                      )
                                                    }}
                                                  />
                                                  <ErrorMessage
                                                    name={`users.${index}.email`}
                                                    component="div"
                                                    className={formFieldError}
                                                  />
                                                </div>
                                                {/* Inclusief IQ-Test */}
                                                <div className={candidateInput}>
                                                  <label
                                                    htmlFor={"iqtest-label"}
                                                    style={{
                                                      marginTop: "25px",
                                                    }}
                                                  >
                                                    <Field
                                                      validate={(value) =>
                                                        undefined
                                                      }
                                                      style={{
                                                        height: "unset",
                                                      }}
                                                      type={"checkbox"}
                                                      name={`users.${index}.iq_test`}
                                                      disabled={submitted}
                                                      onChange={(e) => {
                                                        handleChange(e)
                                                        handleCandidateFieldChange(
                                                          index,
                                                          "iq_test",
                                                          e.target.value
                                                        )
                                                      }}
                                                    />
                                                    <span>
                                                      Inclusief IQ-test
                                                    </span>
                                                  </label>
                                                </div>
                                                {/* Taal */}
                                                <div className={candidateInput}>
                                                  <label htmlFor={field.label}>
                                                    <span>Taal</span>
                                                    {field.isRequired && (
                                                      <span
                                                        style={{
                                                          color: "gray",
                                                        }}
                                                      >
                                                        *
                                                      </span>
                                                    )}
                                                  </label>
                                                  <Field
                                                    validate={(value) =>
                                                      field.isRequired && !value
                                                        ? `Dit veld is verplicht`
                                                        : undefined
                                                    }
                                                    className={formField}
                                                    placeholder={
                                                      field.placeholder
                                                    }
                                                    rows={6}
                                                    style={{
                                                      width: "100%",
                                                      resize: "none",
                                                    }}
                                                    as="select"
                                                    name={`users.${index}.language`}
                                                    disabled={submitted}
                                                    onChange={(e) => {
                                                      handleChange(e)
                                                      handleCandidateFieldChange(
                                                        index,
                                                        "language",
                                                        e.target.value
                                                      )
                                                    }}
                                                  >
                                                    <option value="">
                                                      Kies een taal
                                                    </option>
                                                    <option value="Nederlands">
                                                      Nederlands
                                                    </option>
                                                    <option value="Engels">
                                                      Engels
                                                    </option>
                                                    {/* Add more language options as needed */}
                                                  </Field>
                                                  <ErrorMessage
                                                    name={`users.${index}.language`}
                                                    component="div"
                                                    className={formFieldError}
                                                  />
                                                </div>
                                              </div>
                                              <div className="p-4">
                                                {/* You can display more details here if needed */}
                                                <button
                                                  type="button"
                                                  disabled={submitted}
                                                  onClick={() => {
                                                    analyticsTrackEvent(
                                                      `form${formIndex}_field_cand_remove`
                                                    )
                                                    remove(index)
                                                  }}
                                                >
                                                  Kandidaat verwijderen
                                                </button>
                                              </div>
                                            </CandidateAccordionItem>
                                          ))}

                                        <div
                                          className={buttonWithDividerContainer}
                                        >
                                          <div
                                            className={dividerWrapper}
                                            aria-hidden="true"
                                          >
                                            <div className={divider} />
                                          </div>
                                          <div className={dividerButtonWrapper}>
                                            <button
                                              type="button"
                                              className={dividerButton}
                                              disabled={submitted}
                                              onClick={() => {
                                                analyticsTrackEvent(
                                                  `form${formIndex}_field_cand_add`
                                                )
                                                push({
                                                  firstName: "",
                                                  lastName: "",
                                                  email: "",
                                                  iq_test: false,
                                                  language: "",
                                                })
                                              }}
                                            >
                                              <svg
                                                className={dividerButtonIcon}
                                                version="1.1"
                                                id="Capa_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="800px"
                                                height="800px"
                                                viewBox="0 0 45.402 45.402"
                                              >
                                                <g>
                                                  <path
                                                    d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141
		c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27
		c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435
		c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"
                                                  />
                                                </g>
                                              </svg>
                                              <span>Nieuwe kandidaat</span>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </FieldArray>
                                  {typeof errors.usersCount === "string" && (
                                    <p style={{ color: "red" }}>
                                      Voeg minstens één kandidaat toe
                                    </p>
                                  )}
                                </dl>
                              </div>
                            </div>
                          </label>
                        )}

                        {fieldType === "countrypicker" && (
                          <>
                            <label htmlFor={field.label}>
                              <span>{renderMarkdownLink(field.label)}</span>
                              {field.isRequired && (
                                <span style={{ color: "gray" }}>*</span>
                              )}
                            </label>
                            <CountryDropdownField
                              name="land"
                              isRequired={true}
                              onChange={(e) => {
                                handleChange(e)
                                handleFieldChange(
                                  field.label.toLowerCase().replaceAll(".", ""),
                                  e.target.value
                                )
                              }}
                            />
                          </>
                        )}

                        {fieldType !== "checkbox" &&
                          fieldType !== "radio" &&
                          fieldType !== "customtsl" &&
                          fieldType !== "countrypicker" && (
                            <>
                              <label htmlFor={field.label}>
                                <span>{renderMarkdownLink(field.label)}</span>
                                {field.isRequired && (
                                  <span style={{ color: "gray" }}>*</span>
                                )}
                              </label>
                              <Field
                                validate={(value) =>
                                  field.isRequired && !value
                                    ? `Dit veld is verplicht`
                                    : undefined
                                }
                                className={formField}
                                placeholder={field.placeholder}
                                rows={6}
                                style={{ width: "100%", resize: "none" }}
                                onChange={(e) => {
                                  handleChange(e)
                                  handleFieldChange(
                                    field.label
                                      .toLowerCase()
                                      .replaceAll(".", ""),
                                    e.target.value
                                  )
                                }}
                                as={
                                  fieldType === "textarea"
                                    ? "textarea"
                                    : "input"
                                }
                                type={fieldType}
                                name={field.label
                                  .toLowerCase()
                                  .replaceAll(".", "")}
                                disabled={submitted}
                              />
                            </>
                          )}

                        <ErrorMessage
                          className={`${formFieldError} download-form-field-error`}
                          name={field.label.toLowerCase()}
                          component="div"
                        />
                      </div>
                    )
                  })}
                </div>
              ))}
              {!submitted && !formData.useMollie && (
                <button
                  className={formSubmit}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {formData.buttonText !== ""
                    ? formData.buttonText
                    : "Meer informatie aanvragen"}
                </button>
              )}
              {!submitted && formData.useMollie && (
                <button
                  className={formSubmit}
                  type="button"
                  onClick={() => setShowModal(true)}
                  disabled={isSubmitting}
                >
                  {formData.buttonText !== ""
                    ? formData.buttonText
                    : "Meer informatie aanvragen"}
                </button>
              )}
              <span style={{ color: "gray" }}> * verplicht veld</span>
              {submitted && (
                <div className={thankYouMessage}>
                  <h4>
                    {formData.submitMessageTitle
                      ? formData.submitMessageTitle
                      : "Bedankt!"}
                  </h4>
                  <p>
                    {formData.submitMessage
                      ? formData.submitMessage
                      : "We hebben je gegevens ontvangen, we nemen spoedig contact met je op."}
                  </p>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default DynamicFormBuilder
